import React from 'react'
import styled from 'styled-components'
import WhiteButton from "../Button/WhiteButton"
import PrimaryButton from "../Button/PrimaryButton"
import PhoneIcon from "../Icons/PhoneIcon"
import GetQuoteIcon from "../Icons/GetQuoteIcon"
import QuotePopup from "../QuotePopup"
import { SectionPage } from "../Section"


const Grid = styled.div`
	display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
	align-items:center;
    position:relative;
    z-index:0;
`
const GridLeft = styled.div`
	position: relative;
    width: 100%;
	padding:0 15px;
	text-align:center;
	@media (min-width: 992px){
		text-align:left;
		flex: 0 0 70%;
		max-width: 70%;
	}
`
const GridRight = styled.div`
	position: relative;
    width: 100%;
	padding:0 15px;
	text-align:center;
	@media (min-width: 992px){
		text-align:right;
        flex: 0 0 30%;
        max-width: 30%;
	}
`
const Content = styled.div`	
	& h2 {
		color: #fff;
		font-size: 24px;
        line-height: 34px;
        align-items:center;
		@media (min-width: 768px) {  
			font-size: 34px;
            line-height: 44px;
		}
		@media (min-width: 992px) {  
            text-align:left;
			font-size: 44px;
			line-height: 54px;
		}
		@media (min-width: 1200px) {  
			font-size: 50px;
			line-height: 60px;
		}
		@media (min-width: 1600px) {  
			font-size: 55px;
			line-height: 65px;
		}
	}
	& p {
		color: #fff;
        font-size: 18px;
        line-height: 28px;
		@media (min-width: 992px) {  
			font-size: 20px;
			line-height: 30px;
		}
		@media (min-width: 1200px) {  
			font-size: 24px;
			line-height: 34px;
		}
	}
`
const ButtonToolbar = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
	
`
const ButtonItem = styled.div`
    button, a{
        max-width: 260px;
        width: 100%;
        display: block;
        margin: 0 auto;
        @media (min-width: 992px) {  
            margin:0 0 0 auto;
        }
        .btn{
            display:flex;
        }
    }
	+ .btn-itm{
        margin-top:20px;
    }
`
const BackgroundImage = styled.div`
	background: url(//images.ctfassets.net/z75g1qwq4el7/1aEQQP4amjqrsmVdUPmD3K/879ffd1d8b4272dbd20be49376500d86/cta-background.png);
	background-size: cover;
	height: 100%;
	width: calc(100% - 10px);
	position: absolute;
	top: 0;
	opacity: 0.1;
`

class GetQuoteCta extends React.Component {
    constructor(props) {
    super(props)
    this.state={
        isQuoteVisible:false
    }
    this.handleModalOpen = this.handleModalOpen.bind(this);   
    this.handleModalClose = this.handleModalClose.bind(this); 
    }
    handleModalOpen () {
        this.setState({
            isQuoteVisible:true,
        })
        document.querySelector("body").classList.add("modal-open")
    }
    handleModalClose () {
        this.setState({
            isQuoteVisible:false,
        })
        document.querySelector("body").classList.remove("modal-open")
    }
	render() {	
        return(
            <>
                <SectionPage ept="80px" epb="80px" xpt="40px" xpb="40px" pt="30px" pb="30px" bg="#ED602B" className='section-getprice'>
                    <BackgroundImage />
                    <div className="container">
                        <Grid>
                            <GridLeft>
                                <Content>
                                    <h2 color='#fff'>Get An Instant Pricing For Your Metal Building</h2>
                                    <p>Your Pro Building Specialist Is Here To Help!</p>
                                </Content>
                            </GridLeft>
                            <GridRight>
                                <ButtonToolbar className='btn-toolbar'>   
                                    <ButtonItem className='btn-itm'><a href="tel:8777541818" aria-label="(877) 754-1818" className='btn-lg'><WhiteButton text="(877) 754-1818" icon={<PhoneIcon />}/></a></ButtonItem>
                                    <ButtonItem className='btn-itm'><button aria-label="get a quote"  type='button' onClick={this.handleModalOpen} className='btn-lg'><PrimaryButton text="Get A Free Quote" icon={<GetQuoteIcon />}/></button></ButtonItem>
                                </ButtonToolbar>
                            </GridRight>
                        </Grid>
                    </div>
                </SectionPage>
                <QuotePopup isVisible={this.state.isQuoteVisible} onClose={this.handleModalClose} location="" formName="Product Get a Quote Form" />
            </>
        )
	}
}

export default GetQuoteCta