import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import ProductCard from "../ProductCard"
import ArrowIcon from "../Icons/ArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"
import { v4 } from "uuid"

const ProductSlider = styled.div`
  .card-title {
    min-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .product-dtl {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ProductItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
`

const RightArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #1e4156;
  width: 44px;
  height: 44px;
  @media (min-width: 992px) {
    width: 54px;
    height: 54px;
  }
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(5px);
    }
  }
`
const LeftArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #1e4156;
  width: 44px;
  height: 44px;
  @media (min-width: 992px) {
    width: 54px;
    height: 54px;
  }
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease-in-out;
      transform: translateX(-5px);
    }
  }
`

const ButtonWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.renderSliderContent = this.renderSliderContent.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  renderSliderContent() {
    const { data, location } = this.props
    if (data.length < 1) {
      return <ProductItemGrid>There is no Relative Product</ProductItemGrid>
    }

    return data.map((item, i) => (
      <ProductItemGrid key={v4()}>
        <ProductCard data={item} order={i} key={i} location={location} />
      </ProductItemGrid>
    ))
  }
  render() {
    const { data } = this.props
    let settings = {}
    if (data.length < 2) {
      settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
      }
    } else if (data.length === 2) {
      settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 500,
      }
    } else {
      settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 500,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
    }

    return (
      <div className="container">
        <ProductSlider className="product-slider">
          <Slider {...settings} ref={c => (this.slider = c)}>
            {this.renderSliderContent()}
          </Slider>
        </ProductSlider>
        <ButtonWrapper>
          <LeftArrow onClick={this.previous}>
            <LeftArrowIcon fill="white" />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowIcon fill="white" />
          </RightArrow>
        </ButtonWrapper>
      </div>
    )
  }
}

export default RelatedProductCarousel
